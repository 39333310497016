// extracted by mini-css-extract-plugin
export var buttonCreateContainer = "CountryEditForm__buttonCreateContainer__jygL8";
export var column = "CountryEditForm__column__ogczG";
export var editor = "CountryEditForm__editor__bRi_s";
export var fieldInstructions = "CountryEditForm__fieldInstructions__CibeX";
export var flex = "CountryEditForm__flex__ah4Lc";
export var flexColumn = "CountryEditForm__flexColumn__cUKRo";
export var flexContainer = "CountryEditForm__flexContainer__DAWqk";
export var gap1 = "CountryEditForm__gap1__v5dsP";
export var gap2 = "CountryEditForm__gap2__ylZVp";
export var gap3 = "CountryEditForm__gap3__yDXwm";
export var gap4 = "CountryEditForm__gap4__mhYPJ";
export var gap5 = "CountryEditForm__gap5__A5280";
export var grid = "CountryEditForm__grid__fb3jz";
export var labelContainer = "CountryEditForm__labelContainer__tJTcW";
export var row = "CountryEditForm__row__kHFkx";